<template>
    <b-container fluid>
        <b-row>
            <b-col class="px-0">
                <img src="../../../assets/images/img-sejaagente.png" class="w-100" />
            </b-col>
        </b-row>

        <b-row>
            <b-col align-v="center" class="px-0 coluna-um">
                <div class="agente-conteudo-geral">
                    <p class="agente-cabecalho">Correspondente Bancário da Omni</p>

                    <p>
                        Nossos Correspondentes Bancários são denominados Agentes Omni. Eles comercializam os produtos e serviços do Omni Banco &amp;
                        Financeira e, juntos, atendem todo território nacional, seguindo todas as exigências regulatórias do mercado. Nosso modelo
                        de operação é baseado em uma Rede de correspondentes bancários 100% exclusiva Omni.
                    </p>

                    <b-button class="botao-agente my-3" v-scroll-to="{ element: '#container-precadastro', duration: 1500, offset: -40 }"> Clique Aqui! </b-button>
                </div>
            </b-col>
        </b-row>

        <div id="carousel_banners" class="carousel slide" data-ride="carousel" data-interval="4000">
            <div class="carousel-inner" role="listbox">

                <div class="carousel-item active w-100" style="height:630px;">
                    <div class="d-block w-100 py-5 padding-vantagens-perfil">
                        <div style="max-height:100px;">
                            <img src="../../../assets/images/img-vantagensagente.png" height="100" />
                        </div>

                        <h4 class="py-3 agente-txt-azul">Vantagens</h4>
                        <p>- Alta rentabilidade e uma forte estrutura de apoio interna ao correspondente bancário;</p>
                        <p>- Capacitação da equipe de colaboradores do correspondente bancário sobre os produtos e serviços;</p>
                        <p>- Suporte especializado para orientação dos prodtuos, comercialização e medição de resultados;</p>
                        <p>- Portfólio diversificado de produtos e serviços financeiros.</p>
                    </div>
                </div>

                <div class="carousel-item w-100" style="height:630px;">
                    <div class="d-block w-100 py-5 padding-vantagens-perfil">
                        <div style="max-height:100px;">
                            <img src="../../../assets/images/img-perfilagente.png" height="100" style="margin-left:2rem;" />
                        </div>

                        <h4 class="py-3 agente-txt-azul">Perfil desejado</h4>
                        <p>- Experiência no mercado financeiro;</p>
                        <p>- Conhecimento de financiamentos e empréstimos bancários;</p>
                        <p>- Gestão das áreas de crédito, comercial e cobrança;</p>
                        <p>- Visão empreendedora e disposição para enfrentar desafios de crescimento e sustentabilidade dos negócios;</p>
                        <p>- Estar alinhado com os nossos Valores.</p>
                    </div>
                </div>

            </div>

            <ol class="carousel-indicators pb-4">
                <li data-target="#carousel_banners" :data-slide-to="0" class="active mx-2"></li>
                <li data-target="#carousel_banners" :data-slide-to="1" class="mx-2"></li>
            </ol>

        </div>

    </b-container>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import $ from 'jquery'

export default {
    name: 'MbSejaAgente',
    components: {
        BButton,
    },
    mounted() {
        $('.carousel').carousel();
    }
}
</script>

<style scoped>
    .coluna-um {
        background-color: white;
        color: black;
        text-align: center;
    }

    .agente-conteudo-geral {
        padding: 3rem 8% 0 8%;
        font-family: MuseoSans;
    }

    .agente-cabecalho {
        font-family: MuseoSans;
        font-weight: 900;
        font-size: 28px;
        color: var(--laranja-claro-omni);
        line-height: 120%;
    }

    .botao-agente {
        background-color: var(--azul-claro-omni);
        color: white;
        border-radius: 10px;
        font-weight: 900;
        font-size: 14px;
        padding: 0.7rem 2rem;
    }

        .botao-agente:focus, .botao-agente:hover {
            background-color: var(--azul-omni);
        }

    .padding-vantagens-perfil {
        padding: 0 10%;
        text-align: center;
    }

        .padding-vantagens-perfil > p {
            font-weight: 700;
        }

    .agente-txt-azul {
        font-family: MuseoSans;
        font-weight: 900;
        color: var(--azul-claro-omni);
    }
</style>
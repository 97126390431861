<template>
    <div class="contaiter-fluid banner-agentes">

        <div class="row m-0">
            <div class="banner-img">
                <img :src="loadImg" class="w-100" />
                <div class="p-0 cabecalho banner-texto">{{ banner.txtCabecalho }}</div>
            </div>
        </div>
        <div class="py-3" style="background-color:white;">
            <div class="col-12 px-4">{{banner.txtConteudo}}</div>
        </div>

    </div>
</template>
<script>
    import { LISTA_CORES } from '@/assets/js/dicionarioPaginas';

    export default {
        name: 'MbBannerSejaAgente',
        props: {
            id_comp: Number,
            componente: Object,
        },
        computed: {
           loadImg: function () {
               let pathComplete = `\\storage\\imagens\\${ this.banner.imagem }`;
               return pathComplete;
           }
        },
        data: function() {
            return {
                listaCores: LISTA_CORES,
                banner: {
                    imagem: 'seja-um-agente.jpg',
                    txtCabecalho: 'Venha para o nosso time',
                    txtConteudo: 'Tenha à disposição um portfólio completo de produtos e serviços para ofertar aos seus clientes, com suporte de uma equipe de alta performance para que sua operação aconteça com maior eficiência e segurança.',
                },
            }
        },
        methods: {
            getCorLista: function(COR) {
                return this.listaCores.find(cor => cor.chave === COR);
            }
        },
    }
</script>

<style scoped>
    .banner-agentes {
        text-align:center !important;
    }

    .banner-img {
        position: relative;
        width: 100%;
    }

    .banner-texto {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .cabecalho {
        font-family: MuseoSans;
        font-weight: 900;
        z-index: 100;
        color: white;
        font-size: 2.2rem;
        letter-spacing: 0.15rem;
        line-height: 100%;
    }
    .conteudo {
        z-index: 100;
        color: white;
        font-size: 2.3rem;
        font-weight: 500;
        padding: 3.5rem 5rem 0 2rem;
        line-height: 100%;
    }
   
</style>
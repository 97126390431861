<template>
    <div class="mb-blog-page-container">

        <div class="container-fluid" >
            <div class="row">
                <img src="@/assets/images/header_omniblog_mb.png" class="mb-blog-page-img-header"/>
            </div>
        </div>

        <div class="container-fluid" v-if="resultadoOk">
            <div class="row mb-blog-page-row-resultados">
                <div class="col-12" v-if="posts.length">
                    <div class="d-flex flex-column flex-wrap">

                        <div v-for="(post, index) in posts" :key="index" class="mb-blog-page-caixa-resultados">
                            <router-link :to="`/blog/${post.id}`" :key="index" >
                              <div class="mb-blog-page-img-container">
                                  <img class="mb-blog-page-img" :src="post.url_imagem" alt="imagem publicacao"/>
                              </div>
                              <h5 class="mx-auto mb-blog-page-titulo-pagina"> {{ post.titulo }} </h5>
                            </router-link>
                            <p class="w-100 mb-blog-page-paragrafo-info"> Ano {{ post.ano }} - Edição {{ post.edicao }} </p>
                            <p class="w-100 mb-blog-page-paragrafo-info"> por {{ post.autor }} </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid" v-else>
            <div class="row mb-blog-page-row-nao-encontrado">
                <div class="col-12">
                    <h3>Não existem publicações disponíveis</h3>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'MbBlogPage',
    components: {
    },
    data: function() {
        return {
        }
    },
    methods: {
        ...mapActions({
            getPosts: 'posts/getPosts',
        }),
    },
    computed: {
        ...mapGetters({
            posts: 'posts/posts',
            getPostCounter: 'posts/getPostCounter',
        }),
        resultadoOk() {
            if(this.posts && this.posts.length > 0)
                return true;
            else
                return false;
        },
    },
    created() {
      this.getPosts();
    },
}

</script>

<style scoped>

.mb-blog-page-img-header {
    width: 100%;
    height: auto;
}

.mb-blog-page-caixa-resultados {
    width: 100%;
    max-width: 100%;
    margin: 20px auto;
}

.mb-blog-page-titulo-pagina {
  width: 100%;
  text-align: center;
  padding-top: 25px;
  color: var(--azul-claro-omni);
  font-family: MuseoSans;
  font-weight: 900;
}

.mb-blog-page-titulo-pagina:hover {
    opacity: 0.7;
    text-decoration: underline;
    cursor: pointer;
}

.mb-blog-page-row-nao-encontrado,
.mb-blog-page-row-resultados {
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
    min-height: 300px;
    padding: 20px 20px 20px 20px;
    width: 100%;
}

.mb-blog-page-img-container {
  min-width: 50px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
  .mb-blog-page-img-container:hover {
    opacity: 0.7;
    cursor: pointer;
  }

.mb-blog-page-img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  max-width: 100%;
}

.mb-blog-page-paragrafo-info {
  text-align: center;
  font-family: MuseoSans;
  font-weight: 500;
  padding-bottom: 0px;
  margin: 0 auto;
  font-size: 14px;
}

</style>

<template>
    <div class="mb-caixa-resultado-agente-container">
        <div class="container " v-if="agente">

            <div class="row no-gutters">

                <div class="col-9">
                    <h4 class="mb-caixa-resultado-agente-titulo"> {{ agente.nome }} </h4>
                </div>

                <div class="col-3">

                    <span 
                        :id="`mb-caixa-resultado-agente-whats-${agente.id}`" 
                        class="mb-caixa-resultado-agente-icone"
                    >
                        <img class="mb-caixa-resultado-agente-img" src="@/assets/images/agentes/icone_whats_agentes.png"/>
                    </span> 
                    
                    <span 
                        :id="`mb-caixa-resultado-agente-tel-${agente.id}`"
                        class="mb-caixa-resultado-agente-icone"
                    >
                        <img class="mb-caixa-resultado-agente-img" src="@/assets/images/agentes/icone_telefone_agentes.png"/>
                    </span> 
                    
                    <b-popover
                        :target="`mb-caixa-resultado-agente-whats-${agente.id}`"
                        triggers="hover focus"
                        placement="bottomleft"
                    >
                        <div class="container">
                            <div class="row py-1">
                                <p class="mb-caixa-resultado-agente-paragrafo-contato-pop">
                                    {{ whatsapp? whatsapp: 'Não disponível' }} 
                                </p>
                            </div>
                            <div class="row">
                                <button @click="onCopiarWhats" class="mb-caixa-resultado-agente-botao-pop">Copiar</button>
                                <a :href="`https://api.whatsapp.com/send?phone=55${whatsappNumerico}`" target="_blank" class="mb-caixa-resultado-agente-botao-pop">Mensagem</a>
                            </div>
                        </div>
                    </b-popover>

                    <b-popover
                        :target="`mb-caixa-resultado-agente-tel-${agente.id}`"
                        triggers="hover focus"
                        placement="bottomleft"
                    >
                        <div class="container">
                            <div class="row py-1">
                                <p class="mb-caixa-resultado-agente-paragrafo-contato-pop">
                                    {{ telefone? telefone: 'Não disponível' }}
                                </p>
                            </div>
                            <div class="row">
                                <button @click="onCopiarTelefone" class="mb-caixa-resultado-agente-botao-pop">Copiar</button>
                                <a :href="`tel:${telefone}`" class="mb-caixa-resultado-agente-botao-pop">Ligar</a>
                            </div>
                        </div>
                    </b-popover>

                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="container">

                        <div class="row">
                            <p class="mb-caixa-resultado-agente-paragrafo-grande"> Razao social: </p>
                        </div>
                        <div class="row">
                            <p class="mb-caixa-resultado-agente-texto-pequeno"> {{ agente.razao_social }} </p>
                        </div>

                        <div class="row">
                            <p class="mb-caixa-resultado-agente-paragrafo-grande"> Nome Fantasia: </p>
                        </div>
                        <div class="row">
                            <p class="mb-caixa-resultado-agente-texto-pequeno"> {{ agente.nome }} </p>
                        </div>
                    
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-12">
                    <div class="container">

                        <div class="row">
                            <p class="mb-caixa-resultado-agente-paragrafo-grande"> Endereço: </p>
                        </div>
                        <div class="row">
                            <p class="mb-caixa-resultado-agente-texto-pequeno"> {{ agente.endereco }} </p>
                        </div>
                        <div class="row">
                            <p class="mb-caixa-resultado-agente-texto-pequeno"> {{ agente.bairro }} </p>
                        </div>
                            <div class="row">
                            <p class="mb-caixa-resultado-agente-texto-pequeno"> {{ agente.complemento }} </p>
                        </div>
                        <div class="row">
                            <p class="mb-caixa-resultado-agente-texto-pequeno"> {{ agente.cidade + ', ' + agente.estado }} </p>
                        </div>

                        <div class="row">
                            <p class="mb-caixa-resultado-agente-paragrafo-grande"> CNPJ: </p>
                        </div>
                        <div class="row">
                            <p class="mb-caixa-resultado-agente-texto-pequeno"> {{ agente.cnpj }} </p>
                        </div>
                    
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="container">

                        <div class="row py-1">
                            <p class="mb-caixa-resultado-agente-texto-pequeno">
                                <span class="mb-caixa-resultado-agente-icone">{{ agente.email }}</span> 
                            </p>
                        </div>
                    
                    </div>
                </div>
            </div>
            
            <div class="row no-gutters">
                <h4 class="mb-caixa-resultado-agente-servicos-prestados"> Serviços Prestados </h4>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="container">

                        <div class="row" v-for="(servico, index) in servicosAgente" :key="index">
                            <p class="mb-caixa-resultado-agente-texto-servicos ">
                                {{ '- ' + servico }}
                            </p>
                        </div>

                        <div class="row" v-if="!servicosAgente || servicosAgente.length == 0" >
                            <p class="mb-caixa-resultado-agente-texto-servicos ">
                                - Sem serviços cadastrados
                            </p>
                        </div>
                    
                    </div>
                </div>
            </div>

            
            <div class="row no-gutters" v-if="agente">
                <botao-m class="mb-caixa-resultado-agente-botao-acessar mx-auto">
                    <router-link :to="'/correspondentes/' + agente.uri" class="mb-caixa-resultado-agente-link">
                        Acessar Página do Correspondente
                    </router-link>  
                </botao-m>
            </div>

        </div>
    </div>
</template>

<script>
import BotaoM from '@/components/botoes/BotaoM';
import { mapGetters } from 'vuex';
import { BPopover } from 'bootstrap-vue'

export default {
    name: 'MbCaixaResultadoAgente',
    components: {
        BotaoM,
        BPopover,
    },
    props: {
    agente: Object,
    },
    data: function() {
        return {
        }
    },
    computed: {
    ...mapGetters({
        servicos: 'servicos/servicos',
    }),
    servicosAgente() {
        if(this.agente) {
            if(this.agente.servicos && this.servicos) {
                return this.servicos
                            .filter(servico => this.agente.servicos.includes(servico.id))
                            .map(servico => servico.nome)
                            .sort((servico1, servico2) => servico1.nome > servico2.nome? 1:-1 )
            }
        }
        return [];
    },
    whatsapp() {
        if(this.agente) {
            return (this.agente.whatsapp);
        } else {
            return '';
        }
    },
    telefone() {
        if(this.agente) {
            return (this.agente.telefone);
        } else {
            return '';
        }
    },
    whatsappNumerico() {
        if(this.agente && this.agente.whatsapp) {
            return this.agente.whatsapp.toString().replace(/\D/g,'');
        } else {
            return '';
        }
    },
    },
    methods: {
        onCopiarWhats: function() {
            this.copyTextToClipboard(this.whatsapp)
        },
        onCopiarTelefone: function() {
            this.copyTextToClipboard(this.telefone)
        },
        fallbackCopyTextToClipboard: function(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                return ('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        },
        copyTextToClipboard: function(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(function() {
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.mb-caixa-resultado-agente-container{
    width: 100%;
}

.mb-caixa-resultado-agente-titulo {
    color: var(--azul-claro-omni);
    font-weight: 400;
}

.mb-caixa-resultado-agente-servicos-prestados {
    color: var(--laranja-claro-omni);
    font-weight: 400;
}

.mb-caixa-resultado-agente-paragrafo-grande {
    font-size: 18px;
}

.mb-caixa-resultado-agente-texto-servicos {
    color: var(--azul-claro-omni);
}

.mb-caixa-resultado-agente-texto-pequeno,
.mb-caixa-resultado-agente-texto-servicos,
.mb-caixa-resultado-agente-botao-acessar {
    font-size: 14px;
}

.mb-caixa-resultado-agente-paragrafo-grande,
.mb-caixa-resultado-agente-texto-servicos,
.mb-caixa-resultado-agente-texto-pequeno {
    margin-bottom: 3px;
}

.mb-caixa-resultado-agente-botao-acessar {
    background-color: var(--azul-claro-omni) !important;
    height: 49px !important;
    margin-top: 20px;
}

.mb-caixa-resultado-agente-icone {
    max-height: 15px;
    margin-right: 6px;
}

.mb-caixa-resultado-agente-img {
    width: auto;
    height: 1.8rem;
    position: relative;
    top: -2px;
}

.mb-caixa-resultado-agente-link {
    color: #fff;
}

.mb-caixa-resultado-agente-botao-pop {
    outline: none;
    border: none;
    border-bottom: 1px solid black;
    background: none;
    font-weight: 500;
    word-break: normal;
    margin: 0px 7px;
}

.mb-caixa-resultado-agente-botao-pop:hover {
    background: var(--cinza-omni);
}

.mb-caixa-resultado-agente-paragrafo-contato-pop {
    margin: 4px auto;
}

</style>
<template>
    <div class="mb-encontre-agente-page-container">
        <div class="container mb-encontre-agente-page-w-100">

            <div class="row mb-encontre-agente-page-row-inicial">
                <div class="col-9 mx-auto px-0">

                    <div class="mb-encontre-agente-page-container-imagem-inicial">
                        <img class="mb-encontre-agente-page-imagem-inicial" src="@/assets/images/agentes/agente_omni_busca.png"/>
                    </div>

                </div>
            </div>

            <div class="row mb-encontre-agente-page-segunda-row">
                <div class="col-md-5 mx-auto mt-5">
                    <div class="container">

                        <div class="row">
                            <h3 class="mb-encontre-agente-page-titulo"> Encontre um Correspondente Bancário Omni </h3>
                        </div>
                        <div class="row">
                            <p class="mb-encontre-agente-page-paragrafo">
                                Estamos presentes onde as pessoas estão, com pontos em todas as regiões do Brasil. Entre em contato com um Correspondente Bancário próximo de você
                            </p>
                        </div>

                        <div class="row">
                            <div class="col-12">

                                <div class="container-fluid">

                                    <div class="row py-2">
                                        <div class="col-12 pr-2">
                                            <input v-model="cep" type="text" class="mb-encontre-agente-page-input-cep" placeholder="Insira seu CEP" >
                                        </div>
                                    </div>

                                    <div class="row py-2">
                                        <div class="col-9 pr-0">
                                            <select v-model.number="produto" class="mb-encontre-agente-page-select-produto">
                                                <option  :value="0">Produto</option>
                                                <option
                                                    v-for="(servico, i) in servicosDisponiveis"
                                                    v-bind:key="i"
                                                    :value="servico.id"
                                                >
                                                    {{ servico.nome }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-3 pl-3">
                                            <botao-p 
                                                @click="redireciona"
                                                largura="3.4rem"
                                                altura="3.4rem"
                                            />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <hr class="separador-mb-encontre-agente-page-rodape">
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BotaoP from '@/components/botoes/BotaoP';

export default {
    name: 'EncontreAgentePage',
    components: {
        BotaoP,
    },
    data: function() {
        return {
            produto: 0,
            cep: '',
        }
    },
    methods: {
        redireciona: function() {
            // Limpa caracteres nao numericos
            let cepNumeros = this.cep.replace(/\D/g,'');
            this.$router.push({ name: 'ResultadoAgentesPage', query: { cep: cepNumeros, produto: this.produto } });
        },
    },
    computed: {
        ...mapGetters({
            servicosDisponiveis: 'servicos/servicos',
        }),
    },
        
}

</script>

<style scoped>
.mb-encontre-agente-page-container {
    width: 100%;
    min-height: 900px;
}

.mb-encontre-agente-page-w-100 {
    max-width: 100% !important;
}
.mb-encontre-agente-page-row-inicial {
    background-color: var(--azul-claro-omni);
    min-height: 250px;
}
.mb-encontre-agente-page-container-imagem-inicial {
    position: absolute;
    bottom: -5px;
    margin: 0 auto;
    width: 100%;
}
    .mb-encontre-agente-page-imagem-inicial {
        max-width: 300px;
        width: 100%;
        height: auto;
    }
    @media screen and (min-width: 700px) and (max-width: 1025px) {
        .mb-encontre-agente-page-container-imagem-inicial {
            bottom: -5px;
        }
            .mb-encontre-agente-page-imagem-inicial {
                margin-left: 25%;
            }

        .mb-encontre-agente-page-row-inicial {
            min-height: 350px;
        }
    }

.mb-encontre-agente-page-titulo,
.mb-encontre-agente-page-paragrafo {
    color: var(--chumbo-texto-omni);
    text-align: center;
    margin: 15px auto;
}

.mb-encontre-agente-page-botao-encontre {
    margin: 30px auto;
    height:52px !important;
    width: 55% !important;
}

.mb-encontre-agente-page-input-cep,
.mb-encontre-agente-page-select-produto {
    background-color: #fff;
    border-radius: 8px;
    outline: none;
    border: 1px solid var(--chumbo-texto-omni);
    height: 3.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: medium;
    width: 100%;
}

.mb-encontre-agente-page-select-produto {
    color: var(--azul-claro-omni);
}

</style>

<template>
    <div class="mb-blog-post-page-container mb-blog-post-page-vw-100">

        <div class="container-fluid">
            <div class="row">
                <img src="@/assets/images/header_omniblog_mb.png" class="mb-blog-post-page-img-header"/>
            </div>
        </div>

        <div class="container-fluid" v-if="post">
            <div class="row">
                <div class="mb-blog-post-page-img-container" v-if="post.url_imagem_mb">
                    <img class="mb-blog-post-page-img" :src="post.url_imagem_mb" alt="imagem da publicacao"/>
                </div>
                <div class="mb-blog-post-page-img-container" v-else-if="post.url_imagem">
                    <img class="mb-blog-post-page-img" :src="post.url_imagem" alt="imagem da publicacao"/>
                </div>
            </div>

            <div class="row px-3 my-5 py-3">
                <div class="col-12">

                    <div class="container">
                        <div class="row mb-0">
                            <p class="mb-blog-post-page-ano-edicao mb-0">
                                Ano: {{post.ano}} - Edição {{post.edicao}} 
                            </p>
                        </div>
                        <div class="row mb-4">
                            <p class="mb-blog-post-page-ano-edicao">
                                por: {{post.autor}}  
                            </p>
                        </div>

                        <div class="row mb-4">
                            <h2 class="mb-blog-post-page-titulo">
                                {{post.titulo}} 
                            </h2>
                        </div>

                        <div class="row">
                            <div v-html="post.conteudo" class="mb-blog-post-page-conteudo conteudo-html-components">
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row pb-5 px-3 mb-4">
                <div class="col-12">
                    <router-link :to="'/blog'" class="mb-blog-post-page-voltar">
                        <img src="@/assets/images/seta_laranja.png" class="mb-blog-post-page-voltar-img px-1 mr-1 mb-2"/>
                        <span class="mb-blog-post-page-voltar-span"> Voltar </span> 
                    </router-link>
                </div>
            </div>

        </div>


        <div class="container-fluid" v-else>
            <div class="row mb-blog-post-page-row-resultados">
                <h3>Essa publicação não existe mais</h3>
            </div>
        </div>

        

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'MbBlogPostPage',
    components: {
    },
    props: {
        id: String,
    },
    data: function() {
        return {
        }
    },
    methods: {
        ...mapActions({
            getPosts: 'posts/getPosts',
        }),
    },
    computed: {
        ...mapGetters({
            posts: 'posts/posts',
        }),
        resultadoOk() {
            if(this.posts && this.posts.length > 0)
                return true;
            else
                return false;
        },
        post() {
            if(this.posts && this.posts.length > 0) {
                return this.posts.find(p => p.id == this.id);
            } else {
                return null;
            }
        },
        postsRelacionados() {
            //array1.filter(value => array2.includes(value))
            let postsRelacionados = this.posts
                                        .filter(post => post.id != this.post.id)
                                        // monta novo vetor com tags no formato string
                                        .map(post => ({
                                            ...post, splitTags: post.tags.split(';').filter(tag => tag.length > 0)
                                        }))
                                        // Faz a intersecção das tags com o post da pagina
                                        .filter(post => {
                                            return post.splitTags.filter(tag => this.post.tags.split(';').includes(tag)).length > 0
                                        })
            return postsRelacionados;
        },

    },
    created() {
        if(!this.posts || this.posts.length == 0) {
            this.getPosts();
        }
    },
}

</script>

<style scoped>

.mb-blog-post-page-container {
    font-family: MuseoSans;
    width: 100%;
}

.mb-blog-post-page-img-header {
    width: 100%;
    height: auto;
}

.mb-blog-post-page-titulo-pagina {
  width: 80%;
  text-align: center;
  padding-top: 25px;
  color: var(--azul-claro-omni);
  font-family: MuseoSans;
  font-weight: 900;
}

.mb-blog-post-page-titulo-pagina:hover {
    opacity: 0.7;
    text-decoration: underline;
    cursor: pointer;
}

.mb-blog-post-page-row-nao-encontrado,
.mb-blog-post-page-row-resultados {
    margin-top: 35px;
    min-height: 300px;
    padding: 20px 10px 20px 10px;
}

.mb-blog-post-page-img-container {
    width: 100%;
    max-height: 400px;
    overflow-y: hidden;
}

.mb-blog-post-page-img {
    width: 100%;
    height: auto;
}

.mb-blog-post-page-paragrafo-info-container {
    min-height: 4em;
    width: 100%;
}

.mb-blog-post-page-paragrafo-info {
    text-align: center;
    font-family: MuseoSans;
    font-weight: 500;
    padding-bottom: 0px;
    margin: 0 auto;
    font-size: 14px;
}

.mb-blog-post-page-ano-edicao {
    font-weight: 900;
    font-size: 15px;
}

.mb-blog-post-page-titulo {
    color: var(--azul-claro-omni);
    font-weight: 900;
    font-family: MuseoSans;
    font-size: 28px;
}

.mb-blog-post-page-conteudo {
    color: var(--chumbo-omni);
    width: 100%;
    max-width: 100%;
}

.mb-blog-post-page-titulo-noticias-relacionadas {
    color: var(--azul-claro-omni);
    font-weight: 900;
}

.mb-blog-post-page-relacionadas-img-container {
    width: 100% !important;
    overflow: hidden;
    height: 200px;
}

.mb-blog-post-page-relacionadas-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100%;
    display: block;
}

.mb-blog-post-page-relacionadas-img-mb {
    position: relative;
    width: 100% !important;
}

.mb-blog-post-page-vw-100 {
    max-width: 100vw !important;
    overflow-x: hidden;
}

.mb-blog-post-page-voltar {
    float: left;
    color: var(--laranja-claro-omni);
    font-size: 18px;
}
    .mb-blog-post-page-voltar:hover {
        opacity: 0.7;
    }

.mb-blog-post-page-voltar-span {
    font-weight: 500;
    font-size: 24px;
    padding-top: 4px !important;
}

.mb-blog-post-page-voltar-img {
    transform: rotate(180deg);
}

</style>

<style>
.mb-blog-post-page-conteudo figure img {
    margin: 0 !important;
    width: 100%;
}

.mb-blog-post-page-conteudo figure {
    max-width: 100%;
}
</style>

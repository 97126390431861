<template>
    <div>
        <mb-seja-correspondente></mb-seja-correspondente>
        <mb-banner-agentes></mb-banner-agentes>
        <precadastro-agente></precadastro-agente>
    </div>
</template>

<script>
    import MbSejaCorrespondente from '@/mobile/components/agentes-components/MbSejaAgente.vue'
    import MbBannerAgentes from '@/mobile/components/agentes-components/MbBannerSejaAgente.vue'
    import PrecadastroAgente from '@/components/agentes-components/PrecadastroAgente.vue'

    export default {
        name: 'MbSejaAgentePage',
        components: {
            MbSejaCorrespondente,
            MbBannerAgentes,
            PrecadastroAgente
        },
    }
</script>

<style scoped>
</style>